@import "../../App";

.header-normal {
  background-color: $app-main-color; /* Header color */
  position: relative;
  min-height: 55px;
}

.header-normal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.header-normal-nus-logos {
  position: absolute;
  top: 50%;
  left: min(3vw, 22px);
  transform: translate(0, -50%);
  display: flex;
  //height: 80%;
}

.header-overlay {
  background-color: $app-main-color; /* Header color */
  height: 10vh;
}

.downloadBadges {
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.body-normal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.body-overlay {
  @extend .body-normal;
  background-color: $window-background-color; /* Layer color */
  position: relative;
  margin: -5vh 3% 5vh 3%;
  border-radius: 5px 5px 10px 10px;
}

.nusLogoImageBox {
  position: absolute;
  left: 2vw;
  //opacity: 0.65;
  top: 2vh;
}

.nusLogoImage {
  width: 150px;
}

// For PC size
@media (min-width: $mid-screen-size + 1) {
  .body-overlay {
    min-height: 85%;
  }
}

// For Non-PC size
@media (max-width: $mid-screen-size) {
  .body-overlay {
    min-height: 88%;
  }
}

.body-padding {
  padding: 1%;
}

.footer {
  width: 100%;
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  min-width: 250px;
}
