@import "../../App";

.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 95%;
  margin-left: -3%; // offset the google play store badge left padding to center the entire div
}

.main-container-vertical {
  @extend .main-container;
  flex-direction: column;
  margin: 0;
}

.badge-container {
  height: 100%;
}

.badge-container-vertical {
  width: 100%;
}

.badge {
  @extend .unselectable;

  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
  //height: 60%;
}

.badge-vertical {
  @extend .badge;

  width: 100%;
  margin: 1% 0;
}

.appStoreImage {
  @extend .unselectable;
  height: 68%;
  max-width: 100%;
  object-fit: contain; // resize the image to fit into the container while maintaining its aspect ratio
}

.appStoreImageVertical {
  @extend .unselectable;
  width: 88%;
}

.playStoreImage {
  @extend .unselectable;
  height: 68%;
  max-width: 100%;
  object-fit: contain;
}

.playStoreImageVertical {
  @extend .unselectable;
  width: 88%;
}

.downloadApkImage {
  @extend .unselectable;
  height: 68%;
  max-width: 100%;
  object-fit: contain;
}


.downloadApkImageVertical {
  @extend .unselectable;
  width: 88%;
}

.qr-code-container {
  //display: flex;
  //flex-direction: column;
  //justify-content: center;
  height: 40%;
}

.qr-code {
  //height: 60%;
  //display: block;
  height: 100%;
  //aspect-ratio: 1;
  //width: 128px;
  width: auto;
  margin-top: 20px;
  //margin-bottom: -10px;

  @media (max-width: 480px) {
    //width: 25vw;
    margin-top: 5px;
    //margin-bottom: -20px;
  }
}
