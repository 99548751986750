@import '../../App';

@media (orientation: landscape) {
  .shareImageWindow {
    width: 99vw;
    height: 70vh;
    margin: 0 auto;
  }
}

@media (orientation: portrait) {
  .shareImageWindow {
    width: 100vw;
    height: 65vh;
    margin: 0 auto;
  }
}

.shareImage {
  border-radius: 2%;
  border: 1px solid #847979;
}

.backButton {
  background-color: $app-main-color;
  margin-bottom: 1vh;
}

.sharePanel {
  display: flex;
  justify-content: space-evenly;
  margin-top: 14px;
}

.dividerWords {
  font-weight: lighter !important;
}

.buttonsRow {
  display: flex;
  justify-content: center;
}

.buttons {
  margin: 5px;
}

.centraliseBox {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.storeBox {
  width: 40%;
  margin-top: 3vh;
  margin-bottom: 3vh;
  min-width: 200px;
}
