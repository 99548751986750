.body-error {
  margin-top: 7vh;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  font-size: 22px;
  width: 100%;
}
