@import '../../App';

.generatedImageWindow {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.generatedImageWindowFill {
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: white; // This is needed to serve as the underlying background when image is downloaded
}

.generatedImageWindowBackground {
  width: 100%;
  height: 100%;
  opacity: 0.1;
  // background images are set separately at image1, image2...
}

.generatedImageWindowContent {
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  top: -100%;
}

.image1 {
  background: url("../../assets/quickplay.jpg") no-repeat center center;
}

.image2 {
  background: url("../../assets/panda.jpg") no-repeat center center;
  background-size: cover;
}

.generatedImageWord {
  font-size: 150px;

  width: 100%;
  height: 100%;
}

.dropBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  aspect-ratio: 1 / 1;
  width: 40%;
  position: relative;
}

.characterDescription {
  top: 50%;
  left: 1%;
  position: absolute;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: "Helvetica Neue";
}

.callForAction {
  bottom: 1%;
  left: 50%;
  position: absolute;
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  font-family: "Helvetica Neue";
}

// For PC size
@media (min-width: $mid-screen-size + 1) {
  .smallOptionCell, .optionCell {
    width: 9vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 20%;
  }

  .info {
    font-size: large;
  }
}

// For tablet size
@media (min-width: $small-screen-size + 1) and (max-width: $mid-screen-size) and (min-height: $small-screen-size + 1) {
  .optionCell {
    width: 15vw;
    margin: 1vw;
  }

  .smallOptionCell {
    width: 12vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 40%;
  }

  .info {
    font-size: x-large;
  }
}

// For phone size (portrait)
@media (max-width: $small-screen-size) {
  .smallOptionCell {
    width: 12vw;
    margin: 1vw;
  }

  .optionCell {
    width: 15vw;
    margin: 1vw;
  }

  .centeredBox {
    width: $characterBoxMinWidth;
  }

  .info {
    font-size: medium;
    text-align: left !important;
    padding-left: 5vw;
  }
}

// For phone size (landscape)
@media (min-width: $small-screen-size + 1) and (max-height: $small-screen-size) {
  .smallOptionCell {
    width: 9vw;
    margin: 1vw;
  }

  .optionCell {
    width: 9vw;
    margin: 1vw;
  }

  .centeredBox {
    width: 18%;
  }

  .characterOptionsBox {
    margin: 0 auto !important;
  }

  .info {
    font-size: small;
  }
}

/* Generic Styles below */
.gameBar {
  width: 100%;
  min-height: calc(100vh - 90px);
}

.info {
  font-weight: normal;
  text-align: center;
}

.infoTop {
  top: 0;
}

.belowMessage {
  min-height: calc(100vh - 250px);
  width: 100%;
  height: auto;
  display: inline-grid;
  align-content: center;
}

/* CSS for content within character box */

.characterBox {
  width: 100%;
}

.centeredBox {
  height: 100%;
  margin: 0 auto;
}


.stackedContent {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.structureImage {
  z-index: 99;
}

// Empty style to prevent mis-delete as it was shown as unnecessary class at .js file
.characterAndMaskStack {
}

@for $i from 1 through 5 {
  .characterAndMaskStack:nth-child(#{$i}) {
    z-index: $i - 1;
  }
}

.hStack {
  display: flex;
  width: 100%;
  height: 100%;
}

.vStack {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dropBarOnDragOver, .innerOnDragOver, .outerOnDragOver {
  background-color: #847979;
  opacity: 0.4;
}

/* CSS for content within character options box */

.characterOptionsBox {
  display: table;
  border-spacing: 10px;
  margin: 3vh auto;
  table-layout: fixed;
}

.inline {
  float: left;
}

.optionCellOnDrag {
  overflow: visible;

  .optionImage {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
    opacity: 70%;
  }
}

.optionImage {
  user-select: none; // Needed to prevent image selection on phone
  background-color: #139489;
  width: 100%;
  border-radius: 20%;
}

.optionImage:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
}

.buttonBox {
  margin: 5vh auto;
  width: 90%;
}

.nextButton {
  background-color: $app-main-color;
}

/* Progress Bar */
.progressBar {
  margin: auto;
}

/* Tick Icon */
.tickIcon {
  position: relative;
  top: -8px;
  right: -8px;
  z-index: 101;
  float: right;
  color: darkgreen;
  background-color: white;
}

// For Type 2

@media (orientation: landscape) {
  // Expected height: > 70vh

  .type2TrophyBox {
    margin-top: 5vh;
  }

  .type2Trophy {
    font-size: 20vh;
  }

  .type2CallForAction {
    margin-top: 2vh;
    font-size: 3vh;
  }

  .type2CallForActionEmphasis {
    font-size: 6vh;
  }

  .type2ProgressBarCharacters {
    width: 40px;
    margin-right: 2px;
  }
}


@media (orientation: portrait) {
  .type2TrophyBox {
    margin-top: 5vh;
  }

  .type2Trophy {
    font-size: 20vh;
  }

  .type2CallForAction {
    margin: 4vh 0 4vh 1vw;
    font-size: 2.5vh;
  }

  .type2CallForActionEmphasis {
    font-size: 3vh;
  }

  .type2ProgressBarCharacters {
    font-size: 5vw;
    width: 7vw;
  }
}

.type2TrophyBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.type2Trophy {
  color: #e5d72f;
  text-align: center;
  margin: auto;
  border-bottom: black 2px solid;
}

.type2CallForAction {
  text-align: center;
}

.type2CallForActionEmphasis {
  font-weight: bold;
}

.type2CallForActionClickIcon {
  position: absolute;
  padding-left: 10px;
  padding-top: 10px;
  color: gray;
}

.type2CallForActionLink {
  color: blue;
  text-decoration: underline;
}

.type2ProgressBarBox {
  font-size: large;
  text-align: center;
  justify-content: center;
  align-items: center;

  margin-top: 2%;
}

.type2ProgressBarCharacters {
  display: inline-block;
}

