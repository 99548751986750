@import '../../App';

.body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 85vh;
}

.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;

  @media (max-width: 992px) {
    align-items: center;
  }
}

.image-container {
  position: relative;
  overflow: auto;
  //max-width: 850px;
  min-width: 200px;
  //max-height: 500px;
  border: 2px solid black;
  margin-top: 3vh;
  margin-left: 3vw;
  width: 80vw;
  height: 75vh;

  @media (max-width: 992px) {
    width: 80vw;
    height: 65vh;
    margin-left: 0;
  }
}

.game-miss-text {
  text-align: center;
  margin: 50px 30px;
  background: linear-gradient(to right, #aa076b, #61045f);
  -webkit-background-clip: text;
  color: transparent;
}
