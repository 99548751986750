@import "../../App";

// Animation
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

// For start button
.startButtonArea {
  position: absolute;
  bottom: 10vh;
  width: 70vw;
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 2.5s;
}

.startButton {
  border-color: $font-main-color;
  background: linear-gradient(to left, white 50%, $font-main-color 50%) right;
  background-size: 200% 100%;
  color: $font-main-color;
  transition: all 0.5s ease;
}

.startButton:hover {
  background-position: left;
  color: white;
}

// End of start button
