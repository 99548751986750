.lesson-selector {
  margin: 30px 0 20px 5px;
}

.search-bar {
  margin: 20px 0 20px 5px;
}

.characters-area {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border: 2px solid black;
  border-radius: 30px;
  min-width: 50px;
  display: grid;
  gap: 10px;
}

.candidate-characters-area {
  @extend .characters-area;
  grid-template-columns: repeat(auto-fill, minmax(42px, 1fr));
  min-height: 50px;
}

.selected-characters-area {
  @extend .characters-area;
  grid-template-columns: repeat(10, 42px);
  justify-content: center;
  min-height: 25px;

  @media (max-width: 650px) {
    grid-template-columns: repeat(5, 42px);
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(2, 42px);
  }
  @media (max-width: 225px) {
    grid-template-columns: repeat(1, 42px);
  }
}

.name-input {
  margin: 20px 5px;
  border: 2px solid black;
}

.name-input-text {
  font-weight: bold;
  font-size: 18px;
}

.name-input-text::placeholder {
  font-weight: normal;
  font-size: 18px;
  color: #8A8B86;
}

.image-selector-row {
  margin: 20px;
}

.character-and-tooltip {
  display: flex;
  gap: 10px;
}
