@import '../../App';

.body {
  min-height: 86vh;
  width: 100%;
  border-radius: inherit;
  background-size: 65px 65px;
  background-image: linear-gradient(to right, rgba(57, 54, 52, 0.15) 1px, transparent 2px), linear-gradient(to bottom, rgba(57, 54, 52, 0.15) 1px, transparent 2px);
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-bottom: 1vh;
}

.contact-info {
  position: absolute;
  background-color: rgba(164, 220, 254, 0.7);
  padding: 10px 15px;
  border-radius: 10px;
  width: 335px;
  line-height: 1.5;
  top: 30px;
  right: 20px;
  font-size: 22px;
  font-weight: 700;
  color: black;
}

.contact-info a {
  color: black;
}

.school-icon {
  width: 14vmax;
  min-width: 160px;
  max-width: 210px;
  height: auto;
  margin-top: 6vh;
  margin-bottom: min(50px, 5vh);
}

.btn-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.btn {
  width: 320px;
  height: 40px;
  font-size: 20px;
}

.btn-submit-container {
  @extend .btn-container;
  margin-top: 10px;
}

.btn-verify-container {
  @extend .btn-container;
  margin-top: 40px;
}

.link-reset-pw-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
  margin-bottom: 20px;
}

.link-reset-pw {
  font-size: 19px;
}

.link-reset-pw-text {
  text-decoration: underline;
}

.empty-box {
  height: max(4vh, 10px);
}

.mode-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-mode-selection {
  width: 400px;
  height: 50px;
  font-size: 25px;
  margin-bottom: 20px;
}

.school-icon-initial {
  margin-top: 15vh;
  margin-bottom: 12vh;
  width: 210px;
}

@media (max-width: 992px) {
  .btn {
    font-size: 17px;
    width: 240px;
    height: 36px;
    margin-bottom: 5px;
  }

  .link-reset-pw-text {
    font-size: 16px;
  }

  .body {
    padding-top: 60px;
    min-height: 72vh;
  }

  .contact-info {
    position: relative;
    top: 0;
    right: 0;
    width: 80vw;
    max-width: 700px;
    margin-top: 5vh;
    margin-bottom: -2vh;
    text-align: center;
  }

  .school-icon {
    width: 10vw;
    height: auto;
    margin-top: 6vh;
    margin-bottom: min(10px, 5vh);
  }

  .school-icon-initial {
    margin-top: 6vh;
    margin-bottom: 5vh;
  }
}

@media (max-width: 480px) {
  .school-icon {
    width: 100px;
    min-width: 100px;
  }

  .contact-info {
    width: 80vw;
    margin-bottom: -1vh;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
  }

  .school-icon-initial {
    width: 160px;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }

  .btn-mode-selection {
    width: 260px;
    height: 50px;
    font-size: 22px;
    margin-bottom: 20px;
  }
}
