.body {
  height: 100%;
  width: 100%;
  margin-left: 2.5vw;
  margin-right: 2.5vw;
}

.btn-create-test-container {
  margin-top: 30px;
  margin-bottom: 30px;
}
