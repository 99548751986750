.record-box {
  background-color: #F4F4F4;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 5px;
  border-radius: 20px;
}

.record-info {
  display: flex;
  justify-content: center;
  text-align: center;
}

.record-title {
  @extend .record-info;
  font-size: 22px;
  font-weight: bold;
  margin: 10px 5px;
  word-break: break-word;
}

.record-start-time, .record-end-time {
  @extend .record-info;
  font-size: 17px;
  font-weight: 600;
  margin: 5px 5px;
}

.btn-control {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action {
  width: 100%;
  display: flex;
  margin: 5px 0;
}

.action-text {
  font-size: 18px;
  font-weight: 500;
  margin-left: 5px;
}

@keyframes flash {
  0% {
    background-color: rgb(186, 224, 255); /* Yellow background */
  }
  100% {
    background-color: #F4F4F4; /* Default background color */
  }
}

.highlight {
  animation: flash 1.8s ease-in-out;
}
