.timer {
  right: 2vw;
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

@keyframes shadowEffect {
  0% {
    box-shadow: 0 0 10px 0px rgba(169, 169, 169, 0.7);
  }
  100% {
    box-shadow: 0 0 10px 5px rgba(169, 169, 169, 0.7);
  }
}

.endingTimer {
  animation: shadowEffect 0.5s infinite alternate ease-in-out;
}