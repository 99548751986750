.modal-title-container {
  font-size: 22px;
}

.share-field-container {
  margin: 10px 0 20px 0;
}

.share-field-name {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
}

.share-field-value-container {
  display: flex;
  align-items: center;
}

.share-text {
  border: 1px solid gray;
  border-radius: 5px;
  //height: 40px;
  width: 75%;
  margin-right: 10px;
  padding: 10px 4px 0 8px;
  font-size: 19px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 1.3;
  //overflow: auto;
}

.copy-btn-container {
  width: 100px;
}
