@import '../../App';

// Animations
// Credits for showTopText and showBottomText: Álvaro Montoro (2019)
// https://alvaromontoro.com/blog/67836/recreating-the-ipad-commercial-animation-with-css
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fadeInThenOut {
  0%, 100% {
    opacity: 0
  }
  25%, 75% {
    opacity: 1
  }
}

@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%, 60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

// For title (first quickplay panel)
// Styles adapted from: Álvaro Montoro (2019)
// https://alvaromontoro.com/blog/67836/recreating-the-ipad-commercial-animation-with-css
.animatedTitle {
  color: #222;
  font-family: Roboto, Arial, sans-serif;
  height: 90vmin;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90vmin;
  animation: fadeOut 0.5s linear forwards;
  animation-delay: 4s;
}

.animatedTitle > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.animatedTitle > div div {
  // font-size: 12vmin;
  padding: 2vmin 0;
  position: absolute;
}

.animatedTitle > div div span {
  display: block;
}

.animatedTitle > div.textTop {
  border-bottom: 1vmin solid #000;
  top: 0;
}

.animatedTitle > div.textTop div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}

.animatedTitle > div.textTop div span:first-child {
  color: #767676;
}

.animatedTitle > div.textTop div span {
  color: $font-main-color;

  font-size: xxx-large;
  font-family: "Protest Strike";
}

.animatedTitle > div.textBottom {
  font-size: larger;
  font-family: system-ui;
  bottom: 0;
}

.animatedTitle > div.textBottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

@media(max-height: $small-screen-size) {
  .animatedTitle > div.textTop div span {
    font-size: xx-large;
  }
}

@media(max-width: $small-screen-size) {
  .animatedTitle > div.textTop div span {
    font-size: xx-large;
  }
}

// End of quickplay title (first panel)


// For logos (second panel)
.nusLogoBox {
  width: 100%;
  margin-bottom: 10vh;
  text-align: center;
  animation: fadeInThenOut 4.5s linear forwards;
}

.nusLogoImage {
  width: 100vh;
  max-width: 90vw;
}

.nusLogoTitle {
  color: $font-main-color;
  font-size: xx-large;
  font-family: "Protest Strike";
  margin: 0px 0px 2vh 2vw;
}


@media(max-height: $small-screen-size) {
  .logoTitle {
    font-size: x-large;
  }
}

@media(max-width: $small-screen-size) {
  .logoTitle {
    font-size: x-large;
  }
}

// End of logos (second panel)


// For demo image and reload button (third quickplay panel)
.display {
  width: 100vh;
  max-width: 90vw;
  margin-bottom: 10vh;
  opacity: 0;

  animation: fadeIn 1s linear forwards;
}

.reloadButton {
  position: absolute;
  top: 10vh;
  right: 10vw;
  z-index: 1;


  animation: fadeIn 1s linear forwards;
  animation-delay: 2s;
}

// End of third quickplay panel

// For pin it logo (first home panel)
.logoPanelBox {
  height: 60vh;
  width: 70vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  animation: fadeOut 0.5s linear forwards;
  animation-delay: 4.5s;
}

.pinItLogoBox {
  display: inline-block;
  width: max(25vw, 25vh);
  aspect-ratio: 1 / 1;
  position: relative;
}

.pinItLogo {
  width: max(25vw, 25vh);
  aspect-ratio: 1 / 1;
  position: absolute;
}

.pinItLogoStructure {
  z-index: 1;
}

.pinItLogoLeft, .pinItLogoRight, .pinItLogoFull {
  z-index: 2;
}

.pinItLogoLeft {
  animation: fadeIn 1s linear forwards;
  animation-delay: 1s;
  opacity: 0;
}

.pinItLogoRight {
  animation: fadeIn 1s linear forwards;
  animation-delay: 2s;
  opacity: 0;
}

.pinItLogoFull {
  animation: fadeIn 1s linear forwards;
  animation-delay: 3s;
  opacity: 0;
}

.pinItText {
  display: inline-block;
  width: max(35vw, 35vh);
  position: relative;

  animation: fadeIn 1s linear forwards;
  animation-delay: 3s;
  opacity: 0;
}

// End of first home panel

// For mobile app download (third home panel)

.download-section {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10px;
  animation: fadeInThenOut 4.5s linear forwards;

  @media (max-width: 480px) {
    margin-top: -10px;
  }
}

.download-text {
  color: $font-main-color;
  font-family: "Protest Strike";

  margin-bottom: 12px;

  @media (max-width: 480px) {
    margin-bottom: 8px;
  }
}

.download-badges {
  display: flex;
  height: 100px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

// For modes (fourth home panel)
.modesPanelBox {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  height: 60vh;
  opacity: 0;

  animation: fadeIn 4.5s linear forwards;
}

.modeBox {
  width: 48%;
}

.modesExplanationBox {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.quickPlayExplanationBox::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/quickplay.jpg") no-repeat center center;
  background-size: cover;
  opacity: 0.10;
  content: '';
}

.classroomExplanationBox::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("../../assets/classroom.jpg") no-repeat center center;
  background-size: cover;
  opacity: 0.10;
  content: '';
}

.modeTitle {
  font-size: x-large;
  text-align: center;
  color: $font-main-color;
  font-family: "Protest Strike";
}

.modeExplanation {
  font-size: medium;
  margin: 10px;
  font-family: "Roboto"
}

.reloadButton2 {
  position: absolute;
  top: 5vh;
  right: 5vw;
  z-index: 1;


  animation: fadeIn 1s linear forwards;
  animation-delay: 2s;
}

.contactInfo, .contactInfo a {
  position: relative;
  margin-top: 20px;
  color: black;
}

// End of home fourth panel
