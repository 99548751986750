.body {
  height: 100%;
  width: 100%;
  margin: 30px 0.5vw 30px 0.5vw;
}

.btn-create-test-container {
  margin-top: 30px;
  margin-bottom: 30px;
}

.name-input {
  margin: 20px 5px;
  border: 2px solid black;
}

.name-input-text {
  font-weight: bold;
  font-size: 18px;
}

.name-input-text::placeholder {
  font-weight: normal;
  font-size: 18px;
  color: #8A8B86;
}
