.main-container {
  padding: 0 20px;
}

.join-instruction-container {
  display: flex;
  flex-direction: column;
  margin-top: -3vh;
}

.join-instruction {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  word-break: break-word;
}

.join-instruction-text {
  @extend .join-instruction;
}

.join-instruction-url {
  @extend .join-instruction;
  color: #E60012;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
}

.qr-code {
  width: 250px;
  height: auto;
}

@media (max-width: 480px) {
  .join-instruction-container {
    margin: 0;
  }

  .join-instruction {
    font-size: 22px;
    font-weight: 500;
  }
}