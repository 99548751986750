.control-panel {
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2vh;
}

.text-sort-container {
  margin-right: 10px;
  font-size: 22px;

  @media not (min-width: 576px) {
    margin-right: 8px;
    font-size: 18px;
  }
}

.btn-sort-container {
  width: 250px;

  @media not (min-width: 576px) {
    width: 220px;
  }
}
