@import "../../App";

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  min-height: 500px;
  width: 100%;
}

.test-title {
  display: flex;
  min-width: max(40vw, 300px);
  height: 50px;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  border-radius: 2em;
  padding: 0 25px;
  text-align: center;
  margin: -20vh 25px 10vh 25px;

  background: #4343a9; /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #3397b2, #4343a9); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #3397b2, #4343a9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: #ffffff;
}

.info-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 4px;
}

.info-form-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  height: 50px;
  width: 300px;
  border: 2px solid black;
  border-radius: 4px;
}

.info-form-input {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.info-form-input::placeholder {
  font-weight: bold;
  font-size: 20px;
  color: #a7a7a7;
}

.info-form-btn-container {
  width: 100%;
}

.info-form-btn {
  width: 100%;
  letter-spacing: 1px;
}

.waiting-msg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 4vw;
}

.waiting-msg-text {
  text-align: center;
  word-wrap: anywhere;
}

.waiting-msg-nickname {
  @extend .waiting-msg-text;
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  margin-top: -15vh;
}

.waiting-msg-instruction {
  @extend .waiting-msg-text;
  font-size: 25px;
  font-weight: 600;
}

.waiting-msg-note {
  @extend .waiting-msg-text;
  font-size: 16px;
  position: absolute;
  padding: 0 20px;
  top: 65vh;
  line-height: 1.4;
  font-weight: 300;
}

@media (max-width: 480px) {
  .test-title {
    font-size: 20px !important;
    height: 60px;
  }
}
