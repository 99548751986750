@import "../../App";

.text-container {
  margin-top: 15px;
  padding: 10px 15px;
  border-radius: 10px;

  @media (max-width: $small-screen-size) {
    padding: 5px 5px;
  }
}

.download-message {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: $small-screen-size) {
    font-size: 20px;
  }
}

.learning-detail-messages-container {
  font-size: 21px;
  font-weight: 600;
  color: #000000;

  @media (max-width: $small-screen-size) {
    font-size: 16px;
  }
}

.learning-detail-message {
  margin-bottom: 15px;
  padding: 4px 0 4px 10px;
  //box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.15);
  height: 30px;
  display: flex;
  align-items: center;

  background: linear-gradient(to right, rgba(201, 230, 255, 0.8), rgba(201, 230, 255, 0)); // TODO: Change to props
}

.learning-detail-message:hover {
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
  font-size: 1.1em;
  transition: 0.2s ease-in-out;
}
