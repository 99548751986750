.body {
  min-height: 82vh;
  width: 100%;
  margin: min(3vh, 25px) min(4vw, 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reg-form-container {
  background: rgba(255, 255, 255, 0.7);

  border-radius: 10px;
  padding: 15px 25px 10px 25px;
  width: 800px;
  min-width: 300px;

  @media (max-width: 1000px) {
    width: 80vw;
  }
}

.reg-form-input {
  font-weight: 600;
  font-size: 20px;
}

.btn-reg-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.btn-reg {
  width: 300px;
}

.error-message-container {
  margin-bottom: 10px;
}

.error-message {
  font-size: 18px;
  font-weight: 600;
}
