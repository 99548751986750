@import "../../App";

.main-container,
.view-container {
  display: flex;
  width: 100%;
  height: 80vh;
  justify-content: center;
  align-items: center;
  // border: 1px red solid;
}

.left-container {
  display: flex;
  height: 100%;
  justify-content: center;
  // border: 1px red solid;

  @media (max-width: 850px) {
    display: none;
  }
}

.mobile-app-icon {
  @extend .unselectable;

  width: 300px;
  border-radius: 20%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
}

.right-container {
  display: flex;
  height: 100%;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // border: 1px blue solid;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 220px;
  background-color: $window-background-color;
  border-radius: 5%;
  margin: 8vh 0 4vh 0;
}

.qr-code {
  height: 80%;
  width: 80%;
}

.badges-container {
  display: flex;
  justify-content: center;
  width: 300px;
}

.mobile-display-main-container,
.mobile-display-view-container {
  display: flex;
  width: 100%;
  height: 70vh;
  justify-content: center;
  align-items: center;
}

.mobile-display-left-container {
  display: flex;
  justify-content: center;
  margin-top: 9vh;
}

.mobile-display-mobile-app-icon {
  width: 20vh;
  border-radius: 20%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.mobile-display-badges-container {
  display: flex;
  justify-content: center;
  width: 70vw;
  margin-top: 5vh;
}
