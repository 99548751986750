@import '../../App';

// For PC size
@media (min-width: $mid-screen-size + 1) {
  .gameMenu {
    position: absolute; // To prevent the pinyin and meaning from moving after character solved
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}

.gameMenu {
  width: 100%;
  height: 95%;
}


.loading {
  text-align: center;
}
