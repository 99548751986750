.searchInput {
  margin: 20px 0 20px 5px;
}

.loadingContainer {
  grid-column: span 3; /* Ensures the spinner spans the full width */
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px; /* Keeps grid size stable: make sure same as imageGrid */
}

.imageGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  justify-content: center;
  min-height: 200px;
  align-items: center; 
}

.imageWrapper {
  text-align: center;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  border: 3px solid transparent;
  border-radius: 7%;
  padding: 5px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

  &:hover {
    border-color: #f8e8cd;
  }
}

.selected {
  border-color: #f4c430;
  border-width: thick;
}

.image {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 7%;
  object-fit: cover;
}

.imageLabel {
  font-size: 14px;
  font-weight: 500;
}

.pagination {
  margin-top: 20px;
  text-align: center;
}


