@import '../../App';

.body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 78vh;
  padding: 2vh 1vw 2vh 1vw;
}

.main-container {
  width: 100%;
}

.page-title-text {
  display: flex;
  justify-content: center;
  margin-bottom: 5vh;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.characters-container {
  margin-top: 8vh;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(5, min(150px, 12vw));
  row-gap: 5vh;
  column-gap: 5vw;
}

.question-results-container {
  margin: 0 3vw;
}

.question-result-container {
  background-color: #D9D9D9;
  border-radius: 20px;
  height: 11vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}

.question-character-container {
  width: 20%;
  height: 100%;
  border-radius: 20px;
  background-color: #139489;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question-performance-container {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5%;

  @media (max-width: 1200px) {
    width: 80%;
    padding: 2%;
  }
}

.progress-bar-container {
  width: 80%;
  margin: 0 5% 0 0;

  @media (max-width: 1200px) {
    width: 60%;
  }
}

.question-performance-text {
  width: 15%;
  text-align: center;
  font-weight: 600;
  font-size: 25px;

  @media (max-width: 1200px) {
    width: 20%;
  }
}

.question-performance-text-no-ans {
  @extend .question-performance-text;
  width: 100%;
}

.options-container {
  margin: -2vh 4% 3vh 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
}

.sort-options {
  margin-right: 2%;
}

@media (max-width: 575px) {
  .page-title-text {
    font-size: 22px;
    font-weight: 500;
    word-wrap: break-word;
  }

  .question-result-container {
    height: 7vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1vh;
  }

  .options-container {
    flex-direction: column;
  }

  .sort-options {
    margin-right: 0;
    margin-bottom: 2vh;
  }

  .options-container {
    margin: -2vh 0 1vh 0;
  }

  .question-performance-text {
    font-size: 22px;
  }
}
