@import "../../App";

.main-container {
  position: absolute;
  z-index: 1;
  bottom: 1vw;
  right: 2.5vw;

  @media (max-width: 992px) {
    bottom: 30px;
    right: 20px;
    display: flex;
  }
}

.button {
  //background: linear-gradient(to right, #7fa7ff, #1677ff);
  //transition: background-color 0.3s ease;
  margin-top: 20px;
  @media (max-width: 992px) {
    margin-top: 0;
    margin-right: 20px;
  }
}

.button:hover {
  //background: linear-gradient(to right, #4f8de3, #0f5bbd);
}

.app-get-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}

.app-get-button {
  width: 70%;
  min-width: 300px;
}

.badges-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.badges {
  display: flex;
  height: 28vh;
  width: 50vw;
  justify-content: center;
  align-items: center;
  margin-top: -2vh;
  margin-bottom: 1vh;
}
