@import '../../App';

.body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  padding: 3vh 1vw 1vh 1vw;
  margin-top: 18vh;
  margin-right: 15px;
}

.main-container {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.row-container {
  flex: 1;
  width: 100%;
}

.row {
  height: 100%;
  width: 100%;
}

.participants-area {
  border-radius: 2vmin;
  height: 46vh;
  overflow: auto;
  background-color: rgba(234, 234, 234, 0.5);
  padding: 2.5vmin 1vmin;
}

.participants-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-flow: wrap;
}

.participant-card {
  margin: 1vmin 1.5vmin;
  padding: 2vmin 3vmin;
  border-radius: 0.4vmin;
  font-size: 20px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #c62f60, #753a88);
}

.bottom-area {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 15px;
}

.participant-number-container {
  display: flex;
  justify-content: left;
  font-size: 26px;
  border-radius: 2vmin;
  background-color: rgba(234, 234, 234, 0.5);
  padding: 7px 12px;
  margin-right: 10px;
}

.participant-icon {
  width: 25px;
  margin-right: 10px;
}

.participant-number {
  flex-grow: 1;
  display: flex;
  justify-content: left;
}

.btn-go-container {
  display: flex;
  justify-content: right;
}

.btn-go {
  width: 10vw;
  min-width: 80px;
}

.antd-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-go-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
}

.waiting-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: $app-main-color;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}

@media (max-width: 480px) {
  .body {
    margin-top: 11vh;
  }

  .waiting-message {
    font-size: 22px;
  }
}
