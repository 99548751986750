@import '../../App';

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.room-number {
  position: absolute;
  top: max(-3vh, -20px);
  width: 280px;
  background-color: $app-main-color;
  padding: 0 20px 10px 20px;
  color: white;
  //font-family: "Segoe UI", sans-serif;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  border-radius: 0 0 20px 20px;
}

.test-title-container {
  position: absolute;
  top: 11vh;
  height: 50px;
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  background: radial-gradient(circle at center, rgba(255, 247, 0, 0.3) 0%, rgba(255, 247, 0, 0) 80%);
}

.test-title {
  text-align: center;
  font-weight: 700;
}

@media (max-width: 480px) {
  .room-number {
    width: 200px;
    padding: 0 10px 10px 10px;
    font-size: 28px;
    letter-spacing: 0;
    line-break: anywhere;
    word-break: break-word;
  }

  .test-title-container {
    top: 7vh;
  }

  .test-title {
    font-size: 20px !important;

    /* display on at most 2 lines and overflow with ellipsis */
    word-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-height: 1.5em; /* fallback */
    max-height: 3em; /* fallback */
  }
}
