.main-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.hover-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 140px;
  border-radius: 5%;
  border: 1px solid #828282;
  margin-top: 5px;
}

.qr-code {
  height: 85%;
  width: 85%;
}

.scan-text {
  font-weight: bold;
  font-size: 17px;
  margin-top: 10px;
}

.link {
  display: block;
  width: 100%;
  height: 100%;
}
