@import "../../App";

.header {
  width: 100%;
  height: 70px;
  margin: 10px 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.body {
  width: 100%;
  margin: 20px 40px;
}

.test-info {
  color: white;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.test-title {
  @extend .test-info;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.test-start-time {
  @extend .test-info;
  font-size: 18px;
  margin-top: 10px;
}

.container-title {
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}

.question-results-container {
  border: 1px solid $app-main-color;
  border-radius: 2em;
  background-color: $window-background-color;
  padding: 20px 0;
}

.participants-container {
  @extend .question-results-container;
  margin-top: 20px;
}

@media (max-width: 575px) {
  .header {
    height: 50px;
    margin: 10px 25px;
  }

  .body {
    margin: 20px;
  }

  .test-title {
    font-size: 20px;
    letter-spacing: normal;
  }

  .test-start-time {
    margin-top: 5px;
  }

  .container-title {
    font-size: 20px;
  }
}
