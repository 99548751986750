.test-box {
  background-color: #F4F4F4;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 10px 5px;
  border-radius: 20px;
  //color: red;
}

.test-info {
  display: flex;
  justify-content: center;
  text-align: center;
}

.test-name {
  @extend .test-info;
  font-size: 22px;
  font-weight: bold;
  margin: 10px 5px;
  word-break: break-word;
}

.test-created, .test-last-modified {
  @extend .test-info;
  font-size: 17px;
  font-weight: 600;
  margin: 5px 5px;
}

.btn-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
}

.character-container {
  display: grid;
  align-items: center;
  justify-content: start;
  grid-template-columns: repeat(10, 10%);
  row-gap: 5px;
  margin: 10px;
  padding: 10px;
  border: 2px dashed #139489;
  min-height: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(5, 20%);
  }
}

.character {
  font-family: 'KaiTi_GB2312', sans-serif;
  //font-family: 'adobe-kaiti-std'; // TODO: The margin should not be applied for other font types!
  font-size: 32px;
  font-weight: normal; // TODO: Choose a font that supports Medium weight (500)
  text-align: center;
  //margin: -10px 0; // offset Adobe KaiTi font's vertical padding

  @media (max-width: 480px) {
    font-size: 6vw;
    //margin: -6px 0;
  }
}

.start-link {
  display: block;
  width: 100%;
  height: 100%;
}

.btn-hidden {
  display: none;
}

.collapse {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 25px;
}

.imageContainer {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
}

.imageWrapper {
  display: flex;
  flex-direction: column; // Stack character above image
  align-items: center;
  max-width: 120px;
  margin-bottom: 5px; // Space between rows
}

.characterSmall {
  font-family: 'KaiTi_GB2312', sans-serif;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px; // Space between word and image
  text-align: center;
}

.image {
  border-radius: 7%;
  object-fit: cover;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.noImagePlaceholder {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #888;
  background-color: #f0f0f0;
  border-radius: 7%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

