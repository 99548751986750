@import "../../App";

.pending {
  color: green !important;
}

.navbar {
  height: 55px;
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.navbar-item {
  margin: 2vh 2% 2vh 2%;
}

.item-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: #ffffff;
  }
}

.item-link-active {
  @extend .item-link;
  color: #ffffff;
}

.burger-button, .cross-button {
  color: #ffffff;
  font-size: 24px;
  margin-right: 5px;
  position: absolute;
  right: 20px;
  top: 16px;
}

.bm-menu {
  overflow: hidden !important;
}

.bm-item-list {
  background-color: $app-main-color;
  padding-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
}
