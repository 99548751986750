.description {
  margin-left: 10px;
  text-align: center;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.infoIcon {
  color: #1976f9;
}

.notificationBox {
  padding: 4px 16px;
  border: 1px solid #1976f9;
  background-color: #ddecfd;
  border-radius: 8px;
  display: flex;
  align-items: center;
}

.svg {
  margin-left: 10px;
  height: 40px;
}
