$main-background-color: #ece5dd;
$window-background-color: #fbfbfb;
$app-main-color: #013b62;
$font-main-color: #535353;
$characterBoxMinWidth: 220px;
$mid-screen-size: 1024px;
$small-screen-size: 640px;

.app {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;

  --toastify-toast-width: 480px;
}

.unselectable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.ant-tour {
  width: fit-content;
}
