@import 'App';

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  overscroll-behavior: none;
}

body {
  background-color: $main-background-color;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@font-face {
  font-family: 'adobe-kaiti-std';
  src: url('./fonts/Adobe-Kaiti-Std-Font.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'KaiTi_GB2312';
  src: url('./fonts/KaiTi_GB2312.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'FZKaiTi';
  src: url('./fonts/FZKaiTi.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}
