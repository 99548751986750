.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  position: relative;
}

.hoverEffectSelect:hover {
  background-color: #58E440 !important;
}

.hoverEffectRemove:hover {
  background-color: #F6D6D6 !important;
}

.icon {
  position: absolute;
  top: -6px;
  right: -6px;
}

.icon-tick {
  @extend .icon;
}

.icon-cross {
  @extend .icon;
  visibility: hidden;
}

.hoverEffectRemove:hover .icon-cross {
  visibility: visible;
}
