@import '../../App';

.body {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 60vh;
  padding: 3vh 1vw 1vh 1vw;
  margin-top: 18vh;
  margin-right: 15px;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.row-container {
  flex: 1;
  width: 100%;
}

.row {
  height: 100%;
  width: 100%;
}

.session-progress-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.session-running-text {
  display: flex;
  justify-content: center;
  margin-top: -2vh;
  margin-bottom: 4vh;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.progress-circle-container {
  position: relative;
}

.progress-text {
  // center progress text inside the circular progress bar component
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; // interestingly, the only difference is that the text is not easily selectable without this
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}

.bottom-area {
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 35px;
}

.btn-end-container {
  width: 100%;
  //margin-top: 8vh;
  display: flex;
  justify-content: right;
}

.btn-end {
  width: 180px;
}

@media (max-width: 576px) {
  .progress-text {
    font-size: 24px;
    font-weight: 500;
  }

  .session-progress-container {
    margin-bottom: 5vh;
  }
}

@media (max-width: 480px) {
  .body {
    margin-top: 11vh;
  }

  .session-running-text {
    margin-top: 2vh;
    font-size: 22px;
    font-weight: 500;
    word-wrap: break-word;
  }
}
