@import '../../App';

// For phone size (portrait)
@media (max-width: $small-screen-size) {
  .shareSubheading, .radioOption {
    font-size: 17px !important;
  }
}

.surveyPanel {
  overflow: auto;
  margin-bottom: 2vh;
  padding: 0 2vw 0 1vw;
}

.shareSubheading {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  font-family: "Segoe UI";
  margin: 0;
}

.madeCenter {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  margin-bottom: 4vh;
}

.loading {
  text-align: center;
}

.submitButtonArea {
  width: 100%;
  display: flex;
  justify-content: center;
}
