@import '../../App';

// For PC size
@media (min-width: $mid-screen-size + 1) {
  .smallOptionCell, .optionCell {
    width: 7.5vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 15%;
  }

  .info {
    font-size: 18px;
    margin-left: 38vw;

  }

  .characterOptionsBox {
    margin: 1vh auto;
  }
}

// For tablet size
@media (min-width: $small-screen-size + 1) and (max-width: $mid-screen-size) and (min-height: $small-screen-size + 1) {
  .optionCell {
    width: 15vw;
    margin: 1vw;
  }

  .smallOptionCell {
    width: 12vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 40%;
  }

  .info {
    font-size: 20px;
    margin-left: 28vw;
  }

  .characterOptionsBox {
    margin: 3vh auto;
  }
}

// For phone size (portrait)
@media (max-width: $small-screen-size) {
  .nusLogoImage {
    display: none;
  }

  .smallOptionCell {
    width: 12vw;
    margin: 1vw;
  }

  .optionCell {
    width: 15vw;
    margin: 1vw;
  }

  .centeredBox {
    width: $characterBoxMinWidth;
  }

  .info {
    font-size: 16px;
    text-align: left !important;
    padding-left: 5vw;
  }

  .characterOptionsBox {
    margin: 3vh auto;
  }
}

// For phone size (landscape)
@media (min-width: $small-screen-size + 1) and (max-height: $small-screen-size) {
  .nusLogoImage {
    display: none;
  }

  .smallOptionCell {
    width: 6vw;
    margin: 1vw;
  }

  .optionCell {
    width: 6vw;
    margin: 1vw;
  }

  .centeredBox {
    width: 15%;
  }

  .characterOptionsBox {
    margin: 0 auto !important;
  }

  .info {
    font-size: 14px;
    margin-left: 2vw;
  }

  .progressBarBox {
    bottom: 2% !important;
  }

  .messageDivider {
    margin: 12px 0 12px 0; // Half of original
  }
}

/* Generic Styles below */
.gameBar {
  width: 100%;
  height: 100%;
}

.nusLogoImageBox {
  position: fixed;
  left: 6vw;
  opacity: 0.65;
  top: 6vh;
}

.nusLogoImage {
  width: 150px;
}

.info {
  font-weight: normal;
  text-align: left;
  display: inline-block;

  margin-top: 0;
  margin-bottom: 0;
}

.infoTop {
  top: 0;
}

.hidden {
  color: $window-background-color;
}

.message {
  width: 100%;
}

.belowMessage {
  min-height: 55vh;
  width: 100%;
  height: auto;
  display: inline-grid;
  align-content: center;
}

/* CSS for content within character box */

.characterBox {
  width: 100%;
}

.centeredBox {
  height: 100%;
  margin: 0 auto;
}

.dropBox {
  user-select: none;
  aspect-ratio: 1 / 1;
  width: 100%;
  position: relative;
  background-color: #ffffff;
}

.stackedContent {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.structureImage {
  z-index: 99;
}

// Empty style to prevent mis-delete as it was shown as unnecessary class at .js file
.characterAndMaskStack {
}

@for $i from 1 through 5 {
  .characterAndMaskStack:nth-child(#{$i}) {
    z-index: $i - 1;
  }
}

.hStack {
  display: flex;
  width: 100%;
  height: 100%;
}

.vStack {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.dropBarOnDragOver, .innerOnDragOver, .outerOnDragOver {
  background-color: #847979;
  opacity: 0.4;
}

.dropBar {
  position: relative; // needed for the option box positioning
}

/* CSS for content within character options box */

.characterOptionsBox {
  display: table;
  border-spacing: 10px;
  table-layout: fixed;
}

.inline {
  float: left;
}

.optionCellOnDrag {
  overflow: visible;

  .optionImage {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
    opacity: 70%;
  }
}

.optionImage {
  user-select: none; // Needed to prevent image selection on phone
  background-color: #139489;
  width: 100%;
  border-radius: 20%;
}

.optionImage:hover {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
}

.buttonBox {
  margin: 5vh auto;
  width: 90%;
}

.nextButton {
  background-color: $app-main-color;
}

/* Progress Bar */
.progressBarBox {
  position: absolute;
  bottom: 3%;
  left: auto;
  width: 100%;
}

.progressBar {
  margin: auto auto 0 0;
}

.progressBarPreWord {
  margin: auto 5px 0 auto;

}

/* Tick Icon */
.tickIcon {
  position: relative;
  top: -8px;
  right: -8px;
  z-index: 101;
  float: right;
  color: darkgreen;
  background-color: white;
}

/* Info Button */
.infoButton {
  opacity: 0.75;
  position: absolute;
  top: 10px;
  left: 10px;
  border: 0px;
  box-shadow: transparent;
}

.infoButtonOnBottomRight {
  opacity: 0.75;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 0px;
  box-shadow: transparent;
}
