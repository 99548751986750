.body {
  min-height: 82vh;
  width: 100%;
  margin: min(3vh, 25px) min(4vw, 40px);
  padding: 8px;
  background: #F4F4F4;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-container, .right-container {
  margin: 25px 10px 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row {
  width: 100%;
}

.info-container {
  display: flex;
  font-size: 22px;
  font-weight: 700;
  margin: 10px 0;
  width: 100%;
}

.info-title {
  display: flex;
  justify-content: right;
  width: 20%;
  min-width: 100px;
  margin-right: 6px;
}

.info-value {
  display: flex;
  justify-content: start;
  flex: 1;
  min-width: 200px;
  word-break: break-word;
}

.btn-reset-password-container {
  margin-top: 35px;
  margin-left: 5px;
  width: 240px;
}

.btn-feedback-container {
  margin-top: 15px;
  margin-left: 5px;
  width: 240px;
}

.btn-logout-container {
  margin-left: 5px;
  width: 240px;
}

.zaijian-container {
  display: none;
}

@media (min-width: 992px) {
  .body {
    display: block;
  }

  .left-container {
    margin-left: min(5vw, 100px);
    margin-top: min(6vh, 50px);
    display: block;
  }

  .info-container {
    margin: min(4vh, 50px) 0;
  }

  .info-title {
    display: flex;
    justify-content: right;
    width: 100px;
    margin-right: 6px;
  }

  .btn-reset-password-container {
    margin-top: 40px;
  }

  .right-container {
    margin: min(12vh, 100px) min(5vw, 100px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .btn-logout-container {
    margin-top: 40px;
  }

  .zaijian-container {
    display: flex;
    justify-content: center;
    margin-top: 8vh;
  }

  .zaijian-character {
    margin: 0 15px;
    width: 12vw;
    max-width: 150px;
    height: auto;
  }
}
