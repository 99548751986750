@import '../../App';

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

// For redirect button
.buttonsBox {
  position: absolute;
  width: 80vw;
  opacity: 0;
  animation: fadeIn 1s linear forwards;
  animation-delay: 3s;
  bottom: 5vh;

  display: flex;
  justify-content: space-between;
}

.buttonArea {
  width: 48%;
}

.redirectButton {
  border-color: $font-main-color;
  background: linear-gradient(to left, white 50%, $font-main-color 50%) right;
  background-size: 200% 100%;
  color: $font-main-color;
  transition: all 0.5s ease;
}

.redirectButton:hover {
  background-position: left;
  color: white;
}

// End of redirect button
