@import "../../App";

.error-page-body {
  height: 85vh;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page-msg {
  margin: 5vmin;
  padding: 5vmin;
  font-size: 2em;
  line-height: 1.5em;
  text-align: center;
  border: 2px solid #2196f3;
  border-radius: 1.5em;
}

.btn-refresh {
  margin-top: 40px;
  width: 200px;
}
