@import "../../App";

// For PC size
@media (min-width: $mid-screen-size + 1) {
  .smallOptionCell,
  .optionCell {
    width: 6vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 15%;
  }

  .nusLogoImageBox {
    position: fixed;
    left: 6vw;
    opacity: 0.65;
    top: 6vh;
  }

  .nusLogoImage {
    width: 150px;
  }

  // Header Box (Title + Timer)
  .headerBox {
    display: flex;
    justify-content: space-between;
    margin: 2rem 1rem 1rem;
  }

  .gameTitle {
    align-items: center;
    font-weight: bold;
    font-size: 40px;
    margin: auto 1rem auto auto;
  }

  .progressBar {
    margin: auto;
    width: 100%;
    align-items: center;
  }

  .progressContainer {
    width: 100%;
  }

  .timer {
    margin: auto auto auto 1rem;
  }

  // Top Possible Character Portion

  .possibleCharacterContainer {
    padding: 1rem 1rem 2rem;
    background-color: #ebeae5;
    border-radius: 15px;
  }

  .possibleCharacterCount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
  }

  .possibleCharacterGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px; /* Spacing between buttons */
    margin: 1vh auto 0.5vh;
  }

  // Character card css
  .duplicateCharacterCard {
    position: relative;
    background-color: #adebb3; /* Normal green */
    padding: 0.5rem;
    border-radius: 20%;
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    animation: shakeExpand 0.5s ease-in-out;
  }

  .duplicateCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    animation: shakeExpandImage 0.5s ease-in-out;
  }

  .possibleCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .greyedOutCharacterImage {
    visibility: hidden;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .characterCard {
    position: relative;
    background-color: #adebb3;
    padding: 0.5rem;
    border-radius: 20%;
    width: 70px; /* Adjust size as needed */
    height: 70px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .greyedOutCharacterCard {
    position: relative;
    background-color: #c6c6c6;
    padding: 0.5rem;
    border-radius: 20%;
    width: 70px; /* Adjust size as needed */
    height: 70px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .characterCard:disabled,
  .characterCard:selected,
  .greyedOutCharacterCard:disabled,
  .greyedOutCharacterCard:selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  // Middle Selected Options

  .selectedBox {
    display: flex;
    margin: auto;
  }
  .selectedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spacing between selected options */
    margin: 2rem auto; /* Space between selected options and the grid */
    min-height: 10vh;
    max-width: 30vw;
    align-items: center;
  }

  .selectedOptionCard {
    position: relative;
    background-color: #139489;
    border-radius: 20%;
    width: 110px; /* Adjust size as needed */
    height: 110px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .selectedOptionImage {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image fits inside the card */
  }

  .crossButton {
    position: absolute;
    top: 0px;
    right: -8px;
    background-color: #ff4d4d; /* Red color for the cross */
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    border: 2px solid white;
  }

  .crossButton:hover {
    background-color: #ff1a1a; /* Darker red on hover */
  }

  .plusSign {
    font-size: 36px; /* Adjust size as needed */
    color: #757575; /* Match your theme color */
    margin-left: 5vw; /* Spacing around the + sign */
    align-self: center; /* Center vertically */
  }

  // Bottom Options Selection

  .characterGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px; /* Spacing between buttons */
    margin: 1vh auto;
  }

  .characterButton {
    background-color: #fbfbfb;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .characterButton:disabled,
  .characterButton.selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  .optionImage {
    user-select: none; // Needed to prevent image selection on phone
    background-color: #139489;
    width: 100%;
    border-radius: 20%;
  }

  .optionImage:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
  }

  .imageContainer {
    position: relative; /* Create a stacking context */
    margin-top: 1.5rem;
    width: 100%; /* Adjust as needed */
    height: 100%; /* Adjust as needed */
  }

  .structureImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 0; /* Place the structure image behind the character image */
  }

  .modalImage {
    width: 90%;
    height: 90%;
    object-fit: contain;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 1; /* Place the character image on top of the structure image */
  }

  // Sharing Modal
  .shareModal {
    padding: 1rem;
  }

  .congratulations {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .shareModalIconsContainer {
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 1rem;
  }

  .shareModalPreviewImageContainer {
    margin: 1rem 0rem;
  }

  .trophyGif {
    text-align: center;
  }

  .trophyImage {
    width: 100px;
  }

  .statisticsContainer {
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .statisticsWord {
    font-weight: bold;
    font-size: x-large;
    color: #139489;
  }

  .statisticsTitle {
    font-weight: bold;
    font-size: large;
    margin-bottom: 1rem;
    text-align: center;
  }

  .shareStatistics {
    text-align: center;
    margin-top: 1.5rem;
  }

  .shareStatisticsButton {
    margin: auto;
    padding: 0 1.5rem;
    background-color: #139489;
    min-width: 12rem;
    max-width: 12rem;
  }

  .shareStatisticsButton:hover {
    background-color: #107c73 !important;
  }
}

// For tablet size
@media (min-width: $small-screen-size + 1) and (max-width: $mid-screen-size) and (min-height: $small-screen-size + 1) {
  .optionCell {
    width: 15vw;
    margin: 1vw;
  }

  .smallOptionCell {
    width: 12vw;
    margin: 1vw;
  }

  .centeredBox {
    min-width: $characterBoxMinWidth;
    width: 40%;
  }
}

// For phone size (portrait)
@media (max-width: $small-screen-size) {
  .nusLogoImage {
    display: none;
  }

  .smallOptionCell {
    width: 13vw;
  }

  .optionCell {
    width: 13vw;
  }

  .centeredBox {
    width: $characterBoxMinWidth;
  }

  // Header Box (Title + Timer)
  .headerBox {
    display: flex;
    margin-bottom: 3vh;
    justify-content: center;
  }

  .gameTitle {
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    margin: auto 0;
  }

  .progressBar {
    margin: auto;
    width: 50vw !important;
    align-items: center;
  }

  .progressContainer {
    margin: auto 10px;
  }

  .timer {
    margin: 0;
  }

  // Top Possible Character Portion

  .possibleCharacterContainer {
    padding: 4vw 3vw 5vw;
    background-color: #ebeae5;
    border-radius: 15px;
    width: 85vw;
    margin: auto;
  }

  .possibleCharacterCount {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
  }

  .possibleCharacterGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2vw 0; /* Spacing between buttons */
    margin: 1vh auto 0.5vh;
  }

  // Character card css
  .duplicateCharacterCard {
    position: relative;
    background-color: #adebb3; /* Normal green */
    padding: 2vw;
    border-radius: 20%;
    width: 13vw;
    height: 13vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    animation: shakeExpand 0.5s ease-in-out;
  }

  .duplicateCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    animation: shakeExpandImage 0.5s ease-in-out;
  }

  .possibleCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .greyedOutCharacterImage {
    visibility: hidden;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .characterCard {
    position: relative;
    background-color: #adebb3;
    padding: 2vw;
    border-radius: 20%;
    width: 13vw;
    height: 13vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .greyedOutCharacterCard {
    position: relative;
    background-color: #c6c6c6;
    padding: 2vw;
    border-radius: 20%;
    width: 13vw;
    height: 13vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .characterCard:disabled,
  .characterCard:selected,
  .greyedOutCharacterCard:disabled,
  .greyedOutCharacterCard:selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  // Middle Selected Options

  .selectedBox {
    display: flex;
    margin: auto;
  }

  .selectedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spacing between selected options */
    margin: 2rem auto; /* Space between selected options and the grid */
    min-height: 18vh;
    max-width: 62vw;
    align-items: center;
  }

  .selectedOptionCard {
    position: relative;
    background-color: #139489;
    border-radius: 20%;
    width: 20vw;
    height: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .selectedOptionImage {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image fits inside the card */
  }

  .crossButton {
    position: absolute;
    top: -10px;
    right: -12px;
    background-color: #ff4d4d; /* Red color for the cross */
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    border: 2px solid white;
  }

  .crossButton:hover {
    background-color: #ff1a1a; /* Darker red on hover */
  }

  .plusSign {
    font-size: 36px; /* Adjust size as needed */
    color: #757575; /* Match your theme color */
    margin: 0 6vw; /* Spacing around the + sign */
    align-self: center; /* Center vertically */
  }

  // Bottom Options Selection

  .characterGrid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2vw; /* Spacing between buttons */
    margin: 1vh auto;
  }

  .characterButton {
    background-color: #fbfbfb;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .characterButton:disabled,
  .characterButton.selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  .optionImage {
    user-select: none; // Needed to prevent image selection on phone
    background-color: #139489;
    width: 100%;
    border-radius: 20%;
  }

  .optionImage:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
  }

  .imageContainer {
    position: relative; /* Create a stacking context */
    margin-top: 1.5rem;
    width: 100%; /* Adjust as needed */
    height: 100%; /* Adjust as needed */
  }

  .structureImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 0; /* Place the structure image behind the character image */
  }

  .modalImage {
    width: 90%;
    height: 90%;
    object-fit: contain;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 1; /* Place the character image on top of the structure image */
  }

  // Sharing Modal
  .shareModal {
    padding: 1rem;
  }

  .congratulations {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .shareModalIconsContainer {
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 1rem;
  }

  .shareModalPreviewImageContainer {
    margin: 1rem 0rem;
  }

  .trophyGif {
    text-align: center;
  }

  .trophyImage {
    width: 100px;
  }

  .statisticsContainer {
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .statisticsWord {
    font-weight: bold;
    font-size: x-large;
    color: #139489;
  }

  .statisticsTitle {
    font-weight: bold;
    font-size: large;
    margin-bottom: 1rem;
    text-align: center;
  }

  .shareStatistics {
    text-align: center;
    margin-top: 1.5rem;
  }

  .shareStatisticsButton {
    margin: auto;
    padding: 0 1.5rem;
    background-color: #139489;
    min-width: 12rem;
    max-width: 12rem;
  }

  .shareStatisticsButton:hover {
    background-color: #107c73 !important;
  }
}

// For phone size (landscape)
@media (min-width: $small-screen-size + 1) and (max-height: $small-screen-size) and (orientation: landscape) {
  .nusLogoImage {
    display: none;
  }

  .smallOptionCell {
    width: 14vh;
  }

  .optionCell {
    width: 14vh;
  }

  .centeredBox {
    width: 15%;
  }

  // Header Box (Title + Timer)
  .headerBox {
    display: flex;
    margin-bottom: 3vh;
    justify-content: center;
  }

  .gameTitle {
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    margin: auto 0;
  }

  .progressBar {
    margin: auto;
    width: 70vw !important;
    align-items: center;
  }

  .progressContainer {
    margin: auto 10px;
  }

  .timer {
    margin: 0;
  }

  // Top Possible Character Portion

  .possibleCharacterContainer {
    padding: 2vh 3vh 4vh;
    background-color: #ebeae5;
    border-radius: 15px;
    width: 85vw;
    margin: auto;
  }

  .possibleCharacterCount {
    text-align: right;
    font-weight: bold;
    font-size: 12px;
  }

  .possibleCharacterGrid {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: 2vw 0; /* Spacing between buttons */
    margin: 1vh auto 0.5vh;
    justify-content: center;
  }

  // Character card css
  .duplicateCharacterCard {
    position: relative;
    background-color: #adebb3; /* Normal green */
    padding: 2vh;
    border-radius: 20%;
    width: 13vh;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    animation: shakeExpand 0.5s ease-in-out;
  }

  .duplicateCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    animation: shakeExpandImage 0.5s ease-in-out;
  }

  .possibleCharacterImage {
    filter: brightness(0) saturate(100%); /* Converts the image to black */
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .greyedOutCharacterImage {
    visibility: hidden;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .characterCard {
    position: relative;
    background-color: #adebb3;
    padding: 2vh;
    border-radius: 20%;
    width: 13vh;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .greyedOutCharacterCard {
    position: relative;
    background-color: #c6c6c6;
    padding: 2vh;
    border-radius: 20%;
    width: 13vh;
    height: 13vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .characterCard:disabled,
  .characterCard:selected,
  .greyedOutCharacterCard:disabled,
  .greyedOutCharacterCard:selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  // Middle Selected Options

  .selectedBox {
    display: flex;
    margin: auto;
  }

  .selectedOptionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Spacing between selected options */
    margin: 8vh auto; /* Space between selected options and the grid */
    min-height: 15vh;
    max-width: 30vw;
    align-items: center;
  }

  .selectedOptionCard {
    position: relative;
    background-color: #139489;
    border-radius: 20%;
    width: 15vh;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .selectedOptionImage {
    width: 100%;
    height: 100%;
    object-fit: contain; /* Ensure the image fits inside the card */
  }

  .crossButton {
    position: absolute;
    top: -10px;
    right: -12px;
    background-color: #ff4d4d; /* Red color for the cross */
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    padding: 0;
    border: 2px solid white;
  }

  .crossButton:hover {
    background-color: #ff1a1a; /* Darker red on hover */
  }

  .plusSign {
    font-size: 36px; /* Adjust size as needed */
    color: #757575; /* Match your theme color */
    margin: 0 6vw; /* Spacing around the + sign */
    align-self: center; /* Center vertically */
  }

  // Bottom Options Selection

  .characterGrid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    gap: 2vh; /* Spacing between buttons */
    margin: 1vh auto;
  }

  .characterButton {
    background-color: #fbfbfb;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: auto;
  }

  .characterButton:disabled,
  .characterButton.selected {
    opacity: 0.5; /* Grey out the button */
    cursor: not-allowed;
  }

  .optionImage {
    user-select: none; // Needed to prevent image selection on phone
    background-color: #139489;
    width: 100%;
    border-radius: 20%;
  }

  .optionImage:hover {
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.34);
  }

  // Modal Image

  .imageContainer {
    position: relative; /* Create a stacking context */
    margin-top: 1.5rem;
    width: 40vh; /* Adjust as needed */
    height: 40vh; /* Adjust as needed */
  }

  .structureImage {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 0; /* Place the structure image behind the character image */
  }

  .modalImage {
    width: 90%;
    height: 90%;
    object-fit: contain;
    position: absolute;
    top: 5%;
    right: 5%;
    z-index: 1; /* Place the character image on top of the structure image */
  }

  .modalWordFormed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 5vh;
  }

  // Sharing Modal
  .shareModal {
    padding: 0.5rem;
  }

  .congratulations {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
  }

  .shareModalIconsContainer {
    display: flex;
    justify-content: space-evenly;
    padding: 0rem 1rem;
  }

  .shareModalPreviewImageContainer {
    margin: 1rem 0rem;
  }

  .trophyGif {
    text-align: center;
  }

  .trophyImage {
    width: 60px;
  }

  .divider {
    margin: 14px 0;
  }

  .statisticsContainer {
    margin: auto;
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .statisticsWord {
    font-weight: bold;
    font-size: medium;
    color: #139489;
  }

  .statisticsTitle {
    font-weight: bold;
    font-size: medium;
    margin-bottom: 1vh;
    text-align: center;
  }

  .shareStatistics {
    text-align: center;
    margin-top: 1vh;
  }

  .shareStatisticsButton {
    margin: auto;
    padding: 0 1.5rem;
    background-color: #139489;
    min-width: 12rem;
    max-width: 12rem;
  }

  .shareStatisticsButton:hover {
    background-color: #107c73 !important;
  }
}

/* Generic Styles below */

// .imageContainer {
//   position: relative; /* Create a stacking context */
//   margin-top: 1.5rem;
//   width: 100%; /* Adjust as needed */
//   height: 100%; /* Adjust as needed */
// }

// .structureImage {
//   width: 100%;
//   height: 100%;
//   object-fit: contain;
//   position: relative;
//   z-index: 0; /* Place the structure image behind the character image */
// }

// .modalImage {
//   width: 90%;
//   height: 90%;
//   object-fit: contain;
//   position: absolute;
//   top: 5%;
//   right: 5%;
//   z-index: 1; /* Place the character image on top of the structure image */
// }

@keyframes shakeExpand {
  0% {
    transform: scale(1) translateX(0);
    background-color: #adebb3; /* Normal green */
  }
  20% {
    transform: scale(1.1) translateX(-3px);
    background-color: #76c88a; /* Darker green */
  }
  40% {
    transform: scale(1.1) translateX(3px);
    background-color: #5dae72; /* Even darker green */
  }
  60% {
    transform: scale(1.1) translateX(-3px);
    background-color: #76c88a;
  }
  80% {
    transform: scale(1.1) translateX(3px);
    background-color: #adebb3;
  }
  100% {
    transform: scale(1) translateX(0);
    background-color: #adebb3; /* Back to normal */
  }
}

@keyframes shakeExpandImage {
  0% {
    transform: scale(1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
  20% {
    transform: scale(1.1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
  40% {
    transform: scale(1.1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
  60% {
    transform: scale(1.1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
  80% {
    transform: scale(1.1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
  100% {
    transform: scale(1);
    filter: brightness(0) saturate(100%); /* Converts the image to black */
  }
}

// Animation
.animationBox {
  position: fixed;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.previewText {
  font-weight: bold;
  text-align: center;
  font-size: large;
  margin-bottom: 0.5rem;
}
